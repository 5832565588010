<template>
  <div class="text-container">
    <p>
      Was ist Ihr Zielbild? Wo sehen Sie und Ihre Apotheke sich in den nächsten
      Jahren?
    </p>
    <p>
      Wir helfen Ihnen bei der Entwicklung einer Strategie, um Ihre Träume zu
      verwirklichen.
    </p>
    <p>
      Neben den bundesweiten Markttrends und dem Einfluss des Gesetzgebers
      bieten sich Freiräume, in denen Sie sich verwirklichen können. Nutzen Sie
      Alleinstellungsmerkmale, um sich vom Markt abzuheben. Wir helfen Ihnen
      diese aufzudecken, um hieraus eine zukunftssichere Strategie zu
      entwickeln.
    </p>
  </div>
</template>

<script>
export default {
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Wir helfen Ihnen bei der Entwicklung einer Strategie für Ihre Apotheke, um Ihre Träume zu verwirklichen.",
      },
    ],
  },
};
</script>